import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Testimonial from "./Testimonial";
import Portfolio from "./Portfolio";
import Logos from "./Logos";
import Helmet from "./Helmet";
import Services from "./Services";

class MainDemo extends Component{
    render(){
        
        return(
            <div className="active-dark deercode-home deercode-main">

                <Helmet pageTitle="Deercode - Crafting system since 2015" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* <SliderOne /> */}
                    <div className="slider-activation">
                        {/* Start Single Slide */}
                        <div className="slide slide-style-1 d-flex align-items-center bg_image bg_image--1" data-black-overlay="6">
                            <div className="container position-relative">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="inner">
                                            <h1 className="title deercode-entry-title theme-gradient">CRAFTING SYSTEMS <br/>SINCE 2015</h1>
                                        </div>
                                    </div>

                                    <div className="col-lg-2">
                                        <div className="inner">
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="inner">
                                            <p className="deercode-entry-subtitle"><span className="theme-gradient">Deercode</span> helps users and businesses achieve their goals through beautiful designs by creating quality interactive experiences.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> 
                        {/* End Single Slide */}
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--80 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}
                
                {/* Start Service Area */}
                <div className="container position-relative">
                  <div className="service-wrapper service-white">
                      <Services />
                  </div>
                </div>
                {/* End Service Area */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Logos />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}
                
                <Footer />
                
            </div>
        )
    }
}
export default MainDemo;
