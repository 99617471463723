import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../page-demo/script";

const PortfolioList = [
    {
        image: 'ecoembes',
        category: 'Campaign Website | Development',
        title: 'Ecoembes'
    },
    {
        image: 'wwf',
        category: 'Campaign Website | Development',
        title: 'WWF - La hora del planeta'
    },
    {
        image: 'heineken',
        category: 'Corporate Website | Design & Development',
        title: 'Heineken'
    },
    {
        image: 'masmovil',
        category: 'Corporate Website | Development',
        title: ' MásMovil'
    },
    {
        image: 'ey',
        category: 'Campaign Website | Development',
        title: ' EY - Ernst & Young Global Limited (EYG)'
    },
    {
        image: 'correos',
        category: 'Campaign Website | Development',
        title: 'Correos'
    },
    {
        image: 'kreab',
        category: 'Corporate Website | Development',
        title: 'Kreab'
    },
    {
        image: 'ismaelorfebre',
        category: 'Ecommerce | Design & Development',
        title: ' Ismael Orfebre'
    },
    {
        image: 'cordobatickets',
        category: 'Ecommerce Events | Design & Development',
        title: 'Córdoba Tickets'
    },
    {
        image: 'stanpa',
        category: 'Corporate Website | Development',
        title: 'STANPA - Asociación Nacional de Perfumería y Cosmética'
    },
    {
        image: 'juegoresponsable',
        category: 'Blog | Development',
        title: ' Juego es responsable'
    }
]

class Portfolio extends Component{
    render(){
        let title = 'Últimos proyectos',
        description = 'Ayudando a nuestros clientes a crecer.';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            {/* <h4><a href="/portfolio-details">{value.title}</a></h4> */}
                                            <h4>{value.title}</h4>
                                            {/* <div className="portfolio-button">
                                                <a className="rn-btn" href="/portfolio-details">Case Study</a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;
