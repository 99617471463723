import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Two words come to my mind when I think about Deercode, quality and confidence. They are a professional team always willing to face new challenges. They are an elementary asset in our team.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Ana Simancas</span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>We have been working with Deercode on different projects for more than 2 years. They are really professional, they respect working timings and fulfill objectives. I would highly recommend them.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Luz Cano</span> | Ogilvy</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>They are always available for all we need by solving our problems in a short time. From the first moment we connect with them our project has been growing significantly.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Adrián García</span> | CórdobaTickets</h6>
                                    </div>
                                </div>
                            </TabPanel>
                                                        
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/user.png" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>

                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/user.png" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>

                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/user.png" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;