// React Required
import React, { Component } from "react";
import { Helmet } from "react-helmet"
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

import GA from "./GoogleAnalytics";

// Dark Home Layout
import DarkMainDemo from "./deercode/MainDemo";
import DarkMainDemoES from "./deercode/MainDemoES";
import Contact from "./deercode/Contact";
import KitDigital from "./deercode/KitDigital";
import ContactES from "./deercode/ContactES";
import error404 from "./elements/error404";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
     <>
      <BrowserRouter basename={"/"}>
        { GA.init() && <GA.RouteTracker /> }
	<Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={DarkMainDemo}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/es`}
            component={DarkMainDemoES}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/kit-digital`}
            component={KitDigital}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/es/kit-digital`}
            component={KitDigital}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact`}
            component={Contact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/es/contacto`}
            component={ContactES}
          />

          <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />

          <Route component={error404} />
        </Switch>
      </BrowserRouter>
      	<Helmet>
       		<script
          		src="https://analytics.deercode.com/script.js"
          		data-website-id="a4e42ae0-2add-40b7-9138-f6bb1039dba3"
          		async>
	    	</script>
      	</Helmet>
      </>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
