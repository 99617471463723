import React, { Component } from "react";

class ContactTwo extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnNameFail: 0,
            rnEmail: '',
            rnEmailFail: 0,
            rnMessage: '',
            rnMessageFail: 0,
            sucesfullSend: ""
        };
    }

    handleFormSubmit( event ) {
        
        event.preventDefault();
        const that = this;
        let errors = 0;
        this.setState({
            rnNameFail: false,
            rnEmailFail: false,
            rnMessageFail: false,
            sucesfullSend: ""
        });

        if (this.state.rnName === "") {
            this.setState({ rnNameFail: true });
            errors = 1;
        }

        if (this.state.rnEmail === "" || !validateEmail(this.state.rnEmail)) {
            this.setState({ rnEmailFail: true });
            errors = 1;
        }

        if (this.state.rnMessage === "") {
            this.setState({ rnMessageFail: true });
            errors = 1;
        }

        if (!errors) {
            fetch("https://deercode.com/form.php", {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(response => response.text())
            .then((response) => {

                if (response === "Sucesfull") {
                    that.setState({ sucesfullSend: "Mensaje enviado correctamente." });
                    that.setState({ rnName: "", rnEmail: "", rnMessage: "" });
                } else {
                    that.setState({ sucesfullSend: "Ha ocurrido algún error, inténtelo de nuevo." });
                }
            })
        }

        function validateEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

    }

    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-12">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Hablemos.</h2>
                                <p className="description text-center">Estamos preparados <span role='img' aria-label="Giño">🤟</span>.</p>
                            </div>
                            <div className="form-wrapper">
                                <form action="#">
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={this.state.rnName}
                                            className={this.state.rnNameFail ? "error" : ""}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Nombre *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            value={this.state.rnEmail}
                                            className={this.state.rnEmailFail ? "error" : ""}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Correo *"
                                        />
                                    </label>
                                    
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="message"
                                            name="message"
                                            value={this.state.rnMessage}
                                            className={this.state.rnMessageFail ? "error" : ""}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Mensaje *"
                                        />
                                    </label>
                                   
                                    <button className="rn-button-style--2 btn-solid form-sub" type="submit" value="submit" name="submit" id="form-submit" onClick={e => this.handleFormSubmit(e)}>Enviar</button>

                                    <p className="form-notice">{this.state.sucesfullSend}</p>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;