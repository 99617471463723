import React, { Component } from "react";
import PageHelmet from "./Helmet";
import ContactForm from "./ContactForm";
import Logos from "./Logos";
import Header from "./Header";
import Footer from "./Footer";

class Contact extends Component{

    render(){
        return(
            <div className="active-dark deercode-main deercode-contact"> 
                <React.Fragment>
                    <PageHelmet pageTitle='Contact' />

                    <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                    {/* Start Contact Page Area  */}
                    <div className="rn-contact-page ptb--120 bg_color--1 active-dark">
                        <ContactForm />
                    </div>
                    {/* End Contact Page Area  */}                    

                    {/* Start Brand Area */}
                    <div className="rn-brand-area brand-separation bg_color--5 ptb--120 active-dark">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Logos />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Brand Area */}
                    
                    <Footer />
                    
                </React.Fragment>
            </div>
        )
    }
}
export default Contact