import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Dos palabras identifican a Deercode, calidad y confianza. Con un equipo muy profesional dispuestos a asumir nuevos retos; Son una pieza esencial en nuestros proyectos.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Ana Simancas</span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Hemos trabajado con Deercode en diferentes projectos desde hace mas de dos años. Han sido muy profesionales, respetando los tiempos que manejamos y han conseguido los objetivos que les propusimos. Son altamente recomendables.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Luz Cano</span> | Ogilvy</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Siempre están disponibles para solucionar cualquier problema lo antes posible. Desde que empezamos a trabajar juntos han sido una pieza clave para el crecimiento de nuestro proyecto.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Adrián García</span> | CórdobaTickets</h6>
                                    </div>
                                </div>
                            </TabPanel>
                                                        
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/user.png" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>

                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/user.png" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>

                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/user.png" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;
