import React, { Component } from "react";

const ServiceList = [
    {
        icon: '<ion-icon name="construct-outline"></ion-icon>',
        title: 'Handcrafted design & development',
        description: 'You think We develop. Foreseeing your ideas from a technical perspective transforming dreams into reality.'
    },
    {
        icon: '<ion-icon name="server-outline"></ion-icon>',
        title: 'Monitoring and hosting',
        description: 'Reliability is a must! Guaranteeing high performance systems will impact drastically your businesses.'
    },
    {
        icon: '<ion-icon name="logo-wordpress"></ion-icon>',
        title: 'Wordpress & Woocommerce experts',
        description: 'Your needs it´s our commitment. Easy maintainable solutions are a synonym of success. We understand vendor lock-in therefore our team of experts will guide you to accomplish your goals without being dependent.'
    },
    {
        icon: '<ion-icon name="stats-chart-outline"></ion-icon>',
        title: 'Search Engine Optimization & Data analysis',
        description: 'Put your data to work. Analytics is the core, go further and you’ll be able to analyze web & mobile traffic. Understand user behavior boosting your business by making quick, wise and effective decisions.'
    },
    
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--2">
                                <div className="icon" dangerouslySetInnerHTML={{ __html: val.icon }} />
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p dangerouslySetInnerHTML={{ __html: val.description }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;