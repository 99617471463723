import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Header extends Component{
    constructor(props) {
        super(props);
        window.addEventListener('load', function() {
            //console.log('All assets are loaded')
        })
    }

    render(){

        let logoUrl = <img src="/assets/images/logo/logo-deercode-white.png" alt="Deercode logotype" />;

        return(
            <header className={`header-area formobile-menu container default-color`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/es">
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <Link to="/es/kit-digital" >Kit Digital</Link>
                        <span>|</span>
                        <Link to="/es/contacto" >Contacto</Link>
                        <span>|</span>
                        <Link to="/" >EN</Link>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;