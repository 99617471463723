import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/kreab.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/correos 2.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/ecoembes 2.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/ey.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/masmovil.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/sallenfire.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/wwf.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/mitrestorres.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/cincoenebros.png" alt="Logo Images"/>
                    </li>

                    <li>
                        <img src="/assets/images/brand/applus.png" alt="Logo Images"/>
                    </li>

                    <li>
                        <img src="/assets/images/brand/heineken.png" alt="Logo Images"/>
                    </li>

                    <li>
                        <img src="/assets/images/brand/stanpa.png" alt="Logo Images"/>
                    </li>

                    <li>
                        <img src="/assets/images/brand/inbest.png" alt="Logo Images"/>
                    </li>

                    <li>
                        <img src="/assets/images/brand/proptech-cbre.png" alt="Logo Images"/>
                    </li>

                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;