import React, { Component } from "react";

const ServiceList = [
    {
        icon: '<ion-icon name="construct-outline"></ion-icon>',
        title: 'Diseño y desarrollo web a medida',
        description: 'Piensalo y nosotros lo desarrollamos. Transformamos tus ideas desde un punto de vista técnico para que se hagan realidad.'
    },
    {
        icon: '<ion-icon name="server-outline"></ion-icon>',
        title: 'Monitoring and hosting',
        description: 'La fiabilidad ante todo! Garantizando el uso de sistemas de alto rendimiento conseguiremos maximizar la productividad de tu negocio.'
    },
    {
        icon: '<ion-icon name="logo-wordpress"></ion-icon>',
        title: 'Expertos en Wordpress & Woocommerce',
        description: 'Lo que necesites es nuestro compromiso. Sabemos que las soluciones tecnológicas fáciles de mantener garantizan la independencia de tu negocio, es por ello que, nuestro equipo de expertos te guiarán a conseguir tus objetivos evitando depender de nosotros.'
    },
    {
        icon: '<ion-icon name="stats-chart-outline"></ion-icon>',
        title: 'Search Engine Optimization & Data analysis',
        description: 'Pon a trabajar tus datos. La análitica es crucial para entender el comportamiento de los usuarios en web y en móvil. Con este análisis te ayudaremos a tomar decisiones rápidas, basadas en datos y efectivas para el crecimiento de tu negocio.'
    },
    
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--2">
                                <div className="icon" dangerouslySetInnerHTML={{ __html: val.icon }} />
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p dangerouslySetInnerHTML={{ __html: val.description }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;
