import React, { Component } from "react";
import PageHelmet from "./Helmet";

import Header from "./HeaderES";
import Footer from "./FooterES";

const ServicesList = [
    {
        category: 'Categoría Sitio Web y Presencia en Internet',
        title: 'Kit página web',
        description: 'text',
        price: 'Desde 2000 €',
        featured: "Desarrollamos tu web a medida, desde web básicas hasta complejos sistemas",
        features:[
            'Ideación y diseño',
            'Desarrollo con Wordpress o personalizado',
            'Hosting, dominio y SSL',
            'Posicionamiento en buscadores'
        ]
    },
    {
        category: 'Categoría Comercio Electrónico',
        title: 'Kit ecommerce',
        description: 'text',
        price: 'Desde 2000 €',
        featured: "Te ayudamos a vender online con la creación de tu tienda virtual",
        features:[
            'Desarrollo con Woocomerce o personalizado',
            'Hosting, dominio y SSL',
            'Posicionamiento en buscadores',
            'Integración con pasarelas de pago'
        ]
    },
    {
        category: 'Categoría Venta electrónica',
        title: 'Kit Factura Eléctronica',
        description: 'text',
        price: 'Desde 2000 €',
        featured: "Digitaliza el proceso de emisión, recepción y firmado de las facturas de tu negocio",
        features:[
            'Generacion Facturas en formato electrónico de acuerdo con normativa de Factura-e',
            'Procesos de adopción de nuevas herramientas para gestión de factura electrónica',
            'Servicios locales y en la nube para la gestión de facturación',
        ]
    },
    {
        category: 'Categoría Gestión de redes sociales',
        title: 'Kit Redes Sociales',
        description: 'text',
        price: 'Desde 2500 €',
        featured: "Promociona y da visibilidad a tu negocio en redes sociales",
        features:[
            'Creación y evolución de una estrategia de redes sociales (Social Media Plan)',
            'Monitorización activa de la presencia de su negocio en redes sociales',
            'Gestión, publicación y actualización de diferentes redes sociales',
        ]
    }

]
class KitDigital extends Component{

    render(){
        return(
            <div className="active-dark deercode-main deercode-digital"> 
                <React.Fragment>
                    <PageHelmet pageTitle='KitDigital' />

                    <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                    <div className="contact-form--1">
                        <div className="container">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-12">
                                    <div className="section-title text-left mb--50">
                                        <h2 className="title">Kit Digital.</h2>
                                        <p className="description text-center">
                                            AGENTE DIGITALIZADOR ADHERIDO DEL PROGRAMA KIT DIGITAL
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kit-digital--1">
                        <div className="container">
                            <div class="kit-digital--text">
                                <p>&nbsp;</p>
                                <center><img class="aligncenter" src="/assets/images/logo/kit-digital.png" alt="" width="950" /></center>
                                <p>&nbsp;</p>
                                <h3><strong>SOLUCIONES DE DIGITALIZACIÓN DEERCODE</strong></h3>
                            </div>
                        </div>
                        <div className="container">
                            <div className="service-wrapper service-white">
                                <div className="row">
                                    {ServicesList.map( (val , i) => (
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                            <div className="service service__style--3">
                                                <div className="content">
                                                    <p className="category">{val.category}</p>
                                                    <h3 className="title theme-gradient">{val.title}</h3>
                                                    <p className="price" dangerouslySetInnerHTML={{ __html: val.price }} />
                                                    <p className="featured"><span>{ val.featured }</span></p>
                                                    <ul className="features">
                                                        {val.features.map( (feature) => (
                                                            <li>{feature}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <Footer />
                    
                </React.Fragment>
            </div>
        )
    }
}
export default KitDigital
