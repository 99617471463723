import React, { Component } from "react";

class Footer extends Component{
   
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>¿Estás preparado?</span>
                                        <h2>Empecemos a trabajar juntos</h2>
                                        <a className="rn-button-style--2" href="/es/contacto">
                                            <span>Contacto</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2015-2020 Deercode Labs S.L | Todos los derechos reservados.<br/>Hecho con React <span role='img' aria-label="Giño">😉</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;
